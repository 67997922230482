import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Knoxville, TN',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/R6xeJnjPUGA',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      As one of the largest cities in the Appalachian region, Knoxville has
      positioned itself in recent years as a repository of local culture.
      Knoxville is home to the University of Tennessee, the headquarters of the
      Tennessee Valley Authority, and several national and regional companies.
      It is near the Oak Ridge National Laboratory, a federally funded research
      and development center. Knoxville’s population is becoming more diverse,
      with 28 percent identifying as other than white. Median wages are
      significantly lower for people of color than for their white counterparts
      in Knoxville, with white people earning over $5,000 more than black people
      and Latino people. Since 2010, the poverty rate has increased for white,
      black, Latino, and mixed-race residents. Knoxville may face a skills gap
      if it does not increase educational attainment for black and Latino
      residents. Nearly 60 percent of jobs will require some college education,
      but only half of black residents and just over 40 percent of Latino
      residents meet this job requirement
    </p>

    <p>
      n Knoxville, we conducted individual interviews and hosted small group
      discussions with food policy experts at the United Way, community and
      civic leaders at Urban League of Greater Knoxville, and the leadership of
      the Change Center, all working to tackle many of the same challenges E
      Pluribus Unum seeks to address. We also conducted focus groups with
      college-educated black and white residents and non-college-educated white
      residents. Knoxville residents reported a steady pace of development and
      growth in economic opportunity. Residents in Knoxville expressed that they
      lead socially and geographically segregated lives, leading to the
      prominence of media-driven narratives about certain places in the city and
      different social groups. In Knoxville, we conducted focus groups with
      college-educated black and white residents and non-college-educated white
      residents. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
